<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-on="on"
      >
        New Application
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Who is this application for?</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field
                  v-model="application.firstName"
                  label="First Name"
                  :rules="[
                    rules.required,
                    rules.length(0, 20)
                  ]"
                  :counter="20"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="application.lastName"
                  label="Last Name"
                  :rules="[
                    rules.required,
                    rules.length(0, 20)
                  ]"
                  :counter="20"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="application.email"
                  label="Email"
                  :rules="[
                    rules.required,
                    rules.email
                  ]"
                />
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="dobMenu"
                  v-model="dobMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="application.dateOfBirth"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="application.dateOfBirth"
                    label="Date of Birth"
                    prepend-icon="event"
                    hint="yyyy-mm-dd"
                    :rules="[
                      rules.required
                    ]"
                  />
                  <v-date-picker
                    v-model="application.dateOfBirth"
                    @input="$refs.dobMenu.save(application.dateOfBirth)"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="application.gender"
                  :items="genderOptions"
                  item-text="text"
                  item-value="value"
                  label="Gender"
                  :rules="[
                    rules.required
                  ]"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          flat
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/event-bus'
import validate from '@/custom/validate'

export default {
  data() {
    return {
      dialog: false,
      application: {},
      rules: null,
      valid: false,
      dobMenu: false,
      genderOptions: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.rules = validate.rules
      this.application.repId = this.$route.params.id
      this.application.status = 'incomplete'
    },
    close() {
      this.dialog = false
    },
    save() {
      if (!this.$refs.form.validate()) return
      const {
        firstName, lastName, email, dateOfBirth,
      } = this.application

      this.$store.dispatch('applications/all', { email })
      .then((data) => {
        console.log(data[0])
        if (data.length > 0) {
          let application = data[0]
          // if exsit and created by rep, notfiy application exist
          if(!application.applicantId) {
            alert('Application for this person has already been created.')
            return
          }
          // if exsit and created by applicant, suggest sending take-over request
          if (!confirm('Application for this person has already been created, do you want to send a take-over request?')) { return }
          
          application.status = 'on request'
          application.repId = this.user.userId
          this.update(application)
        } else {
          this.create(this.application)
        }
      })
    },
    update(app) {
      this.$store.dispatch('applications/update', app)
        .then((data) => {
          EventBus.$emit('newAppAdded', data)
          this.$store.dispatch('showMessage', { state: 'success', message: 'Take-over request has been sent.' })
          this.$refs.form.reset()
          this.dialog = false
        })
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'Failed to sent take-over request.' }))
    },
    create(app) {
      this.$store.dispatch('applications/create', app)
        .then((data) => {
          EventBus.$emit('newAppAdded', data)
          this.$refs.form.reset()
          this.dialog = false
        })
    },
  },
}
</script>

<style>

</style>
