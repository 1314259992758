<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h1>{{ heading }}</h1>
            <span>
              {{ subheading }}
            </span>
          </v-flex>
          <v-flex
            xs2
            text-xs-right
          >
            <app-new-dialog />
          </v-flex>
        </v-layout>
        <v-divider />
      </v-flex>
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex
            v-if="ongoingApps.length > 0"
            xs12
          >
            <v-flex xs12>
              <h2>Ongoing Applications</h2>
              <span class="subheading" />
            </v-flex>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-icon medium v-if="!ongoinAppTableMode" @click="ongoinAppTableMode=true">view_list</v-icon>
                <v-icon medium v-else @click="ongoinAppTableMode=false">apps</v-icon>
                
                <v-data-iterator
                  v-if="!ongoinAppTableMode"
                  :items="ongoingApps"
                  :rows-per-page-items="rowsPerPageItems"
                  :pagination.sync="pagination"
                  content-tag="v-layout"
                  row
                  wrap
                  expand
                >
                  <v-flex
                    slot="item"
                    slot-scope="props"
                    xs4
                  >
                    <v-card>
                      <v-card-title>
                        <h4>Application ID: {{ props.item.applicationId }}</h4>
                        <v-spacer />
                        <h5>{{ props.item.status || 'incomplete' }}</h5>
                        <!-- <v-btn icon small >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon small>
                      <v-icon>close</v-icon>
                    </v-btn> -->
                      </v-card-title>
                      <v-divider />
                      <v-list dense>
                        <v-list-tile>
                          <v-list-tile-content>Applicant:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ `${props.item.firstName} ${props.item.lastName}` }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Email:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.email }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Date of Birth:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.dateOfBirth }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="red darken-1"
                          flat
                          :disabled="props.item.status === 'on request'"
                          @click="remove(props.item.id)"
                        >
                          Delete
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          flat
                          :disabled="props.item.status === 'on request'"
                          @click="$router.push(`/applications/${props.item.id}`)"
                        >
                          Countine
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-data-iterator>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="ongoingApps"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.applicationId }}</td>
                    <td class="text-xs-right">
                      {{ props.item.firstName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.lastName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.email }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.status }}
                    </td>
                    <td class="justify-center">
                      <v-btn
                        small
                        color="blue darken-1"
                        flat
                        @click="$router.push(`/applications/${props.item.id}`)"
                      >
                        Countine
                      </v-btn>
                      <v-btn
                        small
                        color="red darken-1"
                        flat
                        @click="remove(props.item.id)"
                      >
                        Delete
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="submittedApps.length > 0"
            xs12
          >
            <v-flex xs12>
              <h2>Application waiting for Signature...</h2>
              <span class="subheading" />
            </v-flex>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-icon medium v-if="!submitAppTableMode" @click="submitAppTableMode=true">view_list</v-icon>
                <v-icon medium v-else @click="submitAppTableMode=false">apps</v-icon>
                <v-data-iterator
                  v-if="!submitAppTableMode"
                  :items="submittedApps"
                  :rows-per-page-items="rowsPerPageItems"
                  :pagination.sync="pagination"
                  content-tag="v-layout"
                  row
                  wrap
                  expand
                >
                  <v-flex
                    slot="item"
                    slot-scope="props"
                    xs4
                  >
                    <v-card>
                      <v-card-title>
                        <h4>Application ID: {{ props.item.applicationId }}</h4>
                        <v-spacer />
                        <h5>{{ props.item.status || 'incomplete' }}</h5><br>
                        <!-- <v-btn icon small >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon small>
                      <v-icon>close</v-icon>
                    </v-btn> -->
                      </v-card-title>
                      <v-divider />
                      <v-list dense>
                        <v-list-tile>
                          <v-list-tile-content>Applicant:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ `${props.item.firstName} ${props.item.lastName}` }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Email:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.email }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Date of Birth:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.dateOfBirth }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="warning"
                          flat
                          @click="popSignRequest(props.item)"
                        >
                          Request Signature
                        </v-btn>
                        <v-btn
                          color="blue darken-1" 
                          flat
                          @click="$router.push(`/applications/${props.item.id}`)"
                        >
                          Edit
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-data-iterator>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="submittedApps"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.applicationId }}</td>
                    <td class="text-xs-right">
                      {{ props.item.firstName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.lastName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.email }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.status }}
                    </td>
                    <td class="justify-center">
                      <v-btn
                        color="blue darken-1" 
                        flat
                        @click="$router.push(`/applications/${props.item.id}`)"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        color="warning"
                        flat
                        @click="popSignRequest(props.item)"
                      >
                        Request Signature
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="signedApps.length > 0"
            xs12
          >
            <v-flex xs12>
              <h2>Application waiting for Approval...</h2>
              <span class="subheading" />
            </v-flex>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-icon medium v-if="!signedAppTableMode" @click="signedAppTableMode=true">view_list</v-icon>
                <v-icon medium v-else @click="signedAppTableMode=false">apps</v-icon>
                <v-data-iterator
                  v-if="!signedAppTableMode"
                  :items="signedApps"
                  :rows-per-page-items="rowsPerPageItems"
                  :pagination.sync="pagination"
                  content-tag="v-layout"
                  row
                  wrap
                  expand
                >
                  <v-flex
                    slot="item"
                    slot-scope="props"
                    xs4
                  >
                    <v-card>
                      <v-card-title>
                        <h4>Application ID: {{ props.item.applicationId }}</h4>
                        <v-spacer />
                        <h5>{{ props.item.status || 'incomplete' }}</h5>
                        
                        <!-- <v-btn icon small >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon small>
                      <v-icon>close</v-icon>
                    </v-btn> -->
                      </v-card-title>
                      <v-divider />
                      <v-list dense>
                        <v-list-tile>
                          <v-list-tile-content>Applicant:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ `${props.item.firstName} ${props.item.lastName}` }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Email:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.email }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Date of Birth:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.dateOfBirth }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="red darken-1"
                          flat
                          @click="viewAsPDF(props.item)"
                        >
                          PDF
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-data-iterator>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="signedApps"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.applicationId }}</td>
                    <td class="text-xs-right">
                      {{ props.item.firstName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.lastName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.email }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.status }}
                    </td>
                    <td class="justify-center">
                      <v-btn
                        color="red darken-1"
                        flat
                        @click="viewAsPDF(props.item)"
                      >
                        PDF
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="approvedApps.length > 0"
            xs12
          >
            <v-flex xs12>
              <h2>Approved Applications</h2>
              <span class="subheading" />
            </v-flex>
            <v-divider />
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-icon medium v-if="!approvedAppTableMode" @click="approvedAppTableMode=true">view_list</v-icon>
                <v-icon medium v-else @click="approvedAppTableMode=false">apps</v-icon>
                <v-data-iterator
                  v-if="!approvedAppTableMode"
                  :items="approvedApps"
                  :rows-per-page-items="rowsPerPageItems"
                  :pagination.sync="pagination"
                  content-tag="v-layout"
                  row
                  wrap
                  expand
                >
                  <v-flex
                    slot="item"
                    slot-scope="props"
                    xs4
                  >
                    <v-card>
                      <v-card-title>
                        <h4>Application ID: {{ props.item.applicationId }}</h4>
                        <v-spacer />
                        <h5>{{ props.item.status || 'incomplete' }}</h5>
                        <!-- <v-btn icon small >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon small>
                      <v-icon>close</v-icon>
                    </v-btn> -->
                      </v-card-title>
                      <v-divider />
                      <v-list dense>
                        <v-list-tile>
                          <v-list-tile-content>Applicant:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ `${props.item.firstName} ${props.item.lastName}` }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Email:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.email }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                          <v-list-tile-content>Date of Birth:</v-list-tile-content>
                          <v-list-tile-content class="align-end">
                            {{ props.item.dateOfBirth }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="red darken-1"
                          flat
                          @click="viewAsPDF(props.item)"
                        >
                          PDF
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-data-iterator>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="approvedApps"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.applicationId }}</td>
                    <td class="text-xs-right">
                      {{ props.item.firstName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.lastName }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.email }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.status }}
                    </td>
                    <td class="justify-center">
                      <v-btn
                        color="red darken-1"
                        flat
                        @click="viewAsPDF(props.item)"
                      >
                        PDF
                      </v-btn>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="signRequestPopup"
      width="400px"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Signature Request
        </v-card-title>
        <v-card-text>
          An email will be sent to applicant for request to sign the application.
        </v-card-text>
        <v-card-actions>
          <v-btn
            flat
            color="primary"
            @click="signRequest()"
          >
            Ok
          </v-btn>
          <v-btn
            flat
            color="primary"
            @click="signRequestPopup = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <a 
            style="font-size:11px;color:grey;vertical-align:text-bottom" 
            @click="downloadPDFPopup = true"
          >Have issue recieving?</a>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="downloadPDFPopup"
      width="400px"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Alternative (Paper signing) 
        </v-card-title>
        <v-card-text>
          <p>This is not recommanded. Only use this option if the applicant cannot recieve signature request email.<br>Click "Download" to download Application PDF for paper signing, and send it to our admission admin at <a href="mailto:stephanie.w@tacademy.ca">stephanie.w@tacademy.ca</a>.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            flat
            color="primary"
            @click="downloadPDFPopup = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            flat
            color="error"
            @click="viewAsPDF(selectedApp), downloadPDFPopup = false, signRequestPopup = false"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventBus from '@/event-bus'
import AppBasicNewDialog from '@/components/application/AppBasicNewDialog.vue'

export default {
  components: {
    'app-new-dialog': AppBasicNewDialog,
  },
  data() {
    return {
      headers: [
        {
          text: 'Application ID',
          align: 'left',
          value: 'applicationId',
          width: '10%',
        },
        { text: 'First Name', value: 'firstName', width: '10%'},
        { text: 'Last Name', value: 'lastName', width: '10%' },
        { text: 'Email', value: 'email', width: '15%' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Action', value: '', width: '30%' },
      ],
      applications: [],
      selectedApp: {},
      rowsPerPageItems: [4, 8, 12],
      items: null,
      pagination: {
        rowsPerPage: 8,
      },
      signRequestPopup: false,
      downloadPDFPopup: false,
      ongoinAppTableMode: false,
      submitAppTableMode: false,
      signedAppTableMode: false,
      approvedAppTableMode: false,
    }
  },
  computed: {
    heading() {
      return 'Applications'
    },
    subheading() {
      return ''
    },
    user() {
      return this.$store.getters.user
    },
    ongoingApps() {
      return this.applications.filter(apps => !apps.status || apps.status === 'incomplete' || apps.status === 'on request')
    },
    submittedApps() {
      return this.applications.filter(apps => apps.status === 'submitted')
    },
    signedApps() {
      return this.applications.filter(apps => apps.status === 'signed')
    },
    approvedApps() {
      return this.applications.filter(apps => apps.status === 'approved')
    },
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    EventBus.$off('newAppAdded')
  },
  methods: {
    init() {
      EventBus.$on('newAppAdded', application => this.applications.push(application))
      this.$store.dispatch('representatives/allAppsById', this.user.userId )
        .then((data) => { this.applications = data || [] })
    },
    remove(id) {
      this.$store.dispatch('applications/delete', id)
        .then(() => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'application deleted...' })
          this.applications = this.applications.filter(item => item.id !== id)
        })
    },
    viewAsPDF({ id, applicationId }) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      this.$store.dispatch('applications/getPdf', id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const url = URL.createObjectURL(file)
          //window.open(fileURL)

          a.href = url
          a.download = applicationId
          a.click()
          window.URL.revokeObjectURL(url)
        })
    },
    popSignRequest(item) {
      this.selectedApp = item
      this.signRequestPopup = true
    },
    signRequest() {
      this.$store.dispatch('applications/signRequest', this.selectedApp.id)
        .then(() => this.$store.dispatch('showMessage', { state: 'success', message: 'request has been sent to applicant\'s email' }))
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'unable to send email to the applicant' }))
        .finally(()=> this.signRequestPopup = false)
    },
  },
}
</script>

<style>

</style>
